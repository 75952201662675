<section id="introduction" class="naturalis-header row d-flex">
  <div class="col-md-12 p-4">
    <h3>{{ "search.subtitle" | translate }}</h3>
    <p>{{ "search.explain" | translate }}</p>
  </div>
</section>

<!-- Basic search that searches all fields -->
<form
  id="formGroup"
  [formGroup]="this.search_form.form"
  (keyup.enter)="this.process_search(this.search_form.form.value)">
  <div class="input-group input-group-lg basic-search">
    <input
      formControlName="basic_term"
      class="form-control"
      #basic_term
      id="basic-term"
      autofocus
      placeholder="{{ 'search.basic_term' | translate }}"
      [readOnly]="this.advanced_search_enabled" />
    <button
      type="button"
      id="search"
      class="btn btn-success"
      title="{{ 'search.search' | translate }}"
      (click)="this.process_search(this.search_form.form.value)"
      [disabled]="!this.search_form.form.valid">
      <mat-icon class="mt-2">search</mat-icon>
    </button>
    <button
      type="button"
      class="btn btn-outline-success"
      title="{{ 'search.clear' | translate }}"
      (click)="this.search_form.reset_form(); this.clear_map(); this.enable_basic_search()">
      <mat-icon class="mt-2">replay</mat-icon>
    </button>
  </div>
</form>
<div class="row search-switches justify-content-center">
  <div class="col-4 text-md-center">
    <mat-slide-toggle
      id="advanced-slide-toggle"
      [(ngModel)]="advanced_search_enabled"
      (change)="on_advanced_search_toggle($event)"
      >{{ "search.advanced" | translate }}</mat-slide-toggle
    >
  </div>
  <div class="col-4 text-md-center">
    <mat-slide-toggle id="geo-slide-toggle" [(ngModel)]="geo_search_enabled" (change)="on_geo_search_toggle($event)">
      {{ "search.geographic" | translate }}</mat-slide-toggle
    >
  </div>
</div>

<!-- Advanced search expansion panel -->
<mat-card *ngIf="advanced_search_enabled" class="advanced">
  <mat-card-header>
    <mat-card-title>
      {{ "search.advanced_search" | translate }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <!-- Toggle buttons for selecting OR or AND between fields -->
    <div class="pt-0 pb-2 text-center">
      <mat-button-toggle-group style="margin: auto" [(ngModel)]="operator">
        <mat-button-toggle value="AND">{{ "search.and" | translate }}</mat-button-toggle>
        <mat-button-toggle value="OR">{{ "search.or" | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer"
          [class.active]="this.search_form.active_domain === 'all'"
          (click)="this.change_active_source('all')">
          {{ "search.all" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer"
          [class.active]="this.search_form.active_domain === 'specimens'"
          (click)="this.change_active_source('specimens')">
          {{ "search.specimens" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer"
          [class.active]="this.search_form.active_domain === 'multimedia'"
          (click)="this.change_active_source('multimedia')">
          {{ "search.multimedia" | translate }}
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          style="cursor: pointer"
          [class.active]="this.search_form.active_domain === 'taxa'"
          (click)="this.change_active_source('taxa')">
          {{ "search.taxa" | translate }}
        </a>
      </li>
    </ul>

    <!-- Search fields. Note that each is created using the search_form_field template -->
    <div class="pt-3 text-center">
      <form [formGroup]="this.search_form.form" class="row">
        <div class="col-md-6">
          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.scientific_name',
              control_name: 'scientific_name',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.common_name',
              control_name: 'common_name',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.family',
              control_name: 'family',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.genus',
              control_name: 'genus',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.epithet',
              control_name: 'epithet',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.source',
              control_name: 'source',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <!--<ng-container-->
          <!--[ngTemplateOutlet]="search_form_field"-->
          <!--[ngTemplateOutletContext]="{-->
          <!--label: 'search.source',-->
          <!--control_name: 'source',-->
          <!--visible_on_domains: ['specimens', 'multimedia', 'taxa']-->
          <!--}">-->
          <!--</ng-container>-->

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.registration_number',
              control_name: 'registration_number',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.collection_name',
              control_name: 'collection_name',
              visible_on_domains: ['specimens', 'multimedia'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.type_status',
              control_name: 'type_status',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.type_material',
              control_name: 'type_material',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.license',
              control_name: 'license',
              visible_on_domains: ['multimedia'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.locality',
              control_name: 'locality',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.phase_stage',
              control_name: 'phase_stage',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_multiple_select_field"
            [ngTemplateOutletContext]="{
              label: 'search.sex',
              control_name: 'sex',
              visible_on_domains: ['specimens, multimedia'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.collector',
              control_name: 'collector',
              visible_on_domains: ['specimens', 'multimedia'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.collector_field_number',
              control_name: 'collector_field_number',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>
        </div>

        <div class="col-md-6">
          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.kingdom',
              control_name: 'kingdom',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.phylum',
              control_name: 'phylum',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.class',
              control_name: 'class',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.order',
              control_name: 'order',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.subgenus',
              control_name: 'subgenus',
              visible_on_domains: ['multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.infraspecific_name',
              control_name: 'infraspecific_name',
              visible_on_domains: ['specimens', 'multimedia', 'taxa'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.old_barcodes',
              control_name: 'old_barcodes',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.chronostratigraphy',
              control_name: 'chronostratigraphy',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.biostratigraphy',
              control_name: 'biostratigraphy',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>

          <ng-container
            [ngTemplateOutlet]="search_form_field"
            [ngTemplateOutletContext]="{
              label: 'search.lithostratigraphy',
              control_name: 'lithostratigraphy',
              visible_on_domains: ['specimens'],
            }">
          </ng-container>
        </div>
        <div class="d-grid col-2 mx-auto">
          <button
            type="button"
            class="btn btn-success btn-lg"
            title="{{ 'search.search' | translate }}"
            (click)="this.process_search(this.search_form.form.value)"
            [disabled]="!this.search_form.form.valid">
            {{ "search.search" | translate }}
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>

<!-- Geo search expansion panel -->
<mat-card *ngIf="this.expand_geo_search()" class="geosearch">
  <mat-progress-bar mode="query" *ngIf="this.nba.loading_data['geo']"></mat-progress-bar>
  <mat-card-header>
    <mat-card-title>
      {{ "geosearch.geosearch" | translate }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <!-- Show a warning label when the geographic search is used -->
    <div class="geowarning-banner">
      <div class="alert alert-light" role="alert">
        {{ "geosearch.banner" | translate }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <!-- Let the map scale with a flex div -->
        <div class="map-container" fxFlex="1 1 100%" #map>
          <google-map
            height.px="map.offsetHeight"
            width="auto"
            [center]="this.maps.center"
            [zoom]="this.maps.zoom"
            (mapClick)="this.maps.add_marker($event)"
            [options]="this.maps.options">
            <map-marker
              *ngFor="let marker_position of this.maps.marker_positions"
              [position]="marker_position"
              [options]="this.maps.marker_options">
            </map-marker>
            <ng-container *ngFor="let polygon of this.maps.polygons">
              <map-polygon [paths]="polygon"></map-polygon>
            </ng-container>
          </google-map>
        </div>
        <div class="instructions">
          {{ "geosearch.draw_instructions" | translate }}
        </div>
      </div>

      <div class="col-md-3 location-list">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a
              class="nav-link"
              style="cursor: pointer"
              [class.active]="this.active_location_list === 'countries'"
              title="{{ 'search.countries' | translate }}"
              (click)="this.change_location_list('countries')">
              <mat-icon class="mr-1">public</mat-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              style="cursor: pointer"
              [class.active]="this.active_location_list === 'municipalities'"
              title="{{ 'search.municipalities' | translate }}"
              (click)="this.change_location_list('municipalities')">
              <mat-icon class="mr-1">location_city</mat-icon>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              style="cursor: pointer"
              [class.active]="this.active_location_list === 'nature'"
              title="{{ 'search.nature' | translate }}"
              (click)="this.change_location_list('nature')">
              <mat-icon class="mr-1">nature_people</mat-icon>
            </a>
          </li>
        </ul>

        <div class="row g-2">
          <div class="col-10">
            <input class="form-control mt-2 mb-2" type="text" placeholder="filter..." [(ngModel)]="location_filter" />
          </div>
          <div class="col-1">
            <label type="button" class="col-form-label-sm btn mt-2" (click)="this.clear_map()">
              <mat-icon>cached</mat-icon>
            </label>
          </div>
        </div>

        <div class="overflow-scroll" style="height: 400px">
          <table class="table table-responsive table-hover">
            <tbody>
              <tr
                id="location-entry"
                *ngFor="let location of this.maps.location_list | simple_filter: location_filter"
                [class.active]="location.id === this.active_location">
                <td (click)="this.select_location(location.id)" style="cursor: pointer">
                  {{ location.localized }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- Template for a single form field -->
<ng-template
  matches
  #search_form_field
  let-label="label"
  let-control_name="control_name"
  let-visible_on_domains="visible_on_domains">
  <form [formGroup]="this.search_form.form" (keyup.enter)="this.process_search(this.search_form.form.value)">
    <mat-form-field class="search_form" appearance="outline" [hidden]="this.hide_search_field(visible_on_domains)">
      <mat-label> {{ label | translate }} {{ this.list_domains(visible_on_domains) }} </mat-label>
      <input matInput formControlName="{{ control_name }}" id="{{ control_name }}" />
      <mat-icon
        matSuffix
        fontIcon="help"
        matTooltip="{{ this.utility.translation_with_variable('tooltip', control_name) | translate }}"></mat-icon>
      <mat-error *ngIf="this.search_form.form.get(control_name).errors?.['pattern']">{{
        "search.disallowed" | translate
      }}</mat-error>
    </mat-form-field>
  </form>
</ng-template>

<!-- Template for a multiple select form field -->
<ng-template
  matches
  #search_form_multiple_select_field
  let-label="label"
  let-control_name="control_name"
  let-visible_on_domains="visible_on_domains">
  <form [formGroup]="this.search_form.form">
    <mat-form-field appearance="outline" class="search_form" [hidden]="this.hide_search_field(visible_on_domains)">
      <mat-label> {{ label | translate }} {{ this.list_domains(visible_on_domains) }} </mat-label>
      <mat-select formControlName="{{ control_name }}" multiple>
        <mat-option *ngFor="let status of this.dropdown.dropdowns[control_name]" [value]="status">
          {{ status }} {{ this.get_source_name(status) }}
        </mat-option>
      </mat-select>
      <mat-icon
        matSuffix
        fontIcon="help"
        matTooltip="{{ this.utility.translation_with_variable('tooltip', control_name) | translate }}"></mat-icon>
    </mat-form-field>
  </form>
</ng-template>
