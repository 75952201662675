import { Identification } from "@bioportal/models/Identification";
/**
 * Typescript object to handle all the incoming multimedia JSON data
 */
export class Multimedia {
  default_value = "—";

  identifications: Identification[] = [];
  multimedia_id = "—";
  license = "—";
  institution = "—";
  collection_name = "—";
  scientific_name = "—";
  source = "—";
  service_access_points: string[] = [];
  uri = "";
  format = "";
  collection_start_date = "—";
  phase_stage: string[] = [];
  gathering_events: any = [];
  sourceSystemId = "—";
  sourceSystemCode = "—";
  loading = false;

  creator = "—";
  copyright = "—";
  description = "—";
  sex = "—";
  date = "—";

  is_nsr: boolean;
  is_dcsr: boolean;

  constructor(multimedia?: Partial<Multimedia>) {
    // Allow the partial initialisation of a multimedia object
    Object.assign(this, multimedia);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param multimedia with JSON data received from the server
   * @author Luuk
   */
  public set(multimedia: any) {
    this.set_identifications(multimedia);

    this.sourceSystemCode = "sourceSystem" in multimedia ? multimedia["sourceSystem"]["code"] : "—";
    this.is_nsr = this.sourceSystemCode == "NSR" ? true : false;
    this.is_dcsr = this.sourceSystemCode == "DCSR" ? true : false;

    this.source = "sourceSystem" in multimedia ? multimedia["sourceSystem"]["name"] : "—";
    this.sex = "sex" in multimedia ? multimedia["sex"] : "—";

    this.multimedia_id = "sourceSystemId" in multimedia ? multimedia["sourceSystemId"] : "—";
    this.institution = "sourceInstitutionID" in multimedia ? multimedia["sourceInstitutionID"] : "—";
    this.license = "license" in multimedia ? multimedia["license"] : "—";
    this.service_access_points = "serviceAccessPoints" in multimedia ? multimedia["serviceAccessPoints"] : "—";
    this.uri = "serviceAccessPoints" in multimedia ? multimedia["serviceAccessPoints"][0]["accessUri"] : "—";
    this.format = "serviceAccessPoints" in multimedia ? multimedia["serviceAccessPoints"][0]["format"] : "—";
    this.collection_name = "collectionType" in multimedia ? multimedia["collectionType"] : "—";
    this.institution = "sourceInstitutionID" in multimedia ? multimedia["sourceInstitutionID"] : "—";
    this.sourceSystemId = "sourceSystemId" in multimedia ? multimedia["sourceSystemId"] : "—";
    this.phase_stage = "phasesOrStages" in multimedia ? multimedia["phasesOrStages"] : [];
    this.gathering_events = "gatheringEvents" in multimedia ? multimedia["gatheringEvents"] : [];

    this.creator = "creator" in multimedia ? multimedia["creator"] : "—";
    this.copyright = "copyrightText" in multimedia ? multimedia["copyrightText"] : "—";
    this.description = "description" in multimedia ? multimedia["description"] : "—";

    if (this.phase_stage.length == 0) {
      this.phase_stage = ["—"];
    }

    if (this.gathering_events.length > 0) {
      if ("dateTimeBegin" in this.gathering_events[0]) {
        this.date = this.gathering_events[0]["dateTimeBegin"].split("T")[0];
      }
    }
  }

  public medium_image() {
    let re = /large$/gi;
    const uri = String(this.uri);
    if (uri.match(re)) {
      return uri.replace(re, "medium");
    }
    re = /original\//gi;
    if (uri.match(re)) {
      return uri.replace(re, "w800/");
    }
    return uri;
  }

  public has_scientific_name(): boolean {
    return this.identifications.every((obj: Identification) => obj.scientificName.fullScientificName !== "—");
  }

  public registration_number() {
    return this.multimedia_id.split("_")[0];
  }

  public is_image() {
    return this.format === "image/jpeg" || this.format === "image/png";
  }

  public is_video() {
    return this.format === "video/mp4";
  }

  public readyLoading() {
    this.loading = false;
  }

  public startLoading() {
    this.loading = true;
  }

  /**
   * Sets the identifications, which is a list of nested objects.
   * @param multimedia (any)
   * @author Luuk
   */
  private set_identifications(multimedia: any) {
    if ("identifications" in multimedia) {
      multimedia["identifications"].forEach((identification: any) => {
        this.identifications.push(new Identification(identification));
      });
    }

    // Sort the array so that the object without ? in the scientificNameGroup comes first
    this.identifications.sort((a, b) => {
      // If a is preferred, it should come before b
      if (!a.scientificName.scientificNameGroup.includes("?") && b.scientificName.scientificNameGroup.includes("?")) {
        return -1;
      }
      // If b is preferred, it should come before a
      if (a.scientificName.scientificNameGroup.includes("?") && !b.scientificName.scientificNameGroup.includes("?")) {
        return 1;
      }
      // If both are preferred or both are not preferred, their order doesn't matter
      return 0;
    });
  }
}
