import { DefaultClassification } from "@bioportal/models/DefaultClassification";
import { ScientificName } from "@bioportal/models/ScientificName";
import { VernacularName } from "@bioportal/models/VernacularName";

/**
 * Typescript object to handle all the incoming content pages from the JSON data
 */
export class Identification {
  defaultClassification: DefaultClassification = new DefaultClassification({});
  preferred: boolean;
  scientificName: ScientificName = new ScientificName({});
  taxonRank = "—";
  typeStatus = "—";
  vernacularNames: VernacularName[] = [];

  constructor(content: any) {
    this.set(content);
  }

  /**
   * Converts the JSON received from the server to a Typescript object
   * @param content with JSON data received from the server
   * @author Luuk
   */
  public set(content: any) {
    this.taxonRank = "taxonRank" in content ? content["taxonRank"] : "";
    this.typeStatus = "typeStatus" in content ? content["typeStatus"] : "";
    this.preferred = "preferred" in content ? content["preferred"] : "";

    if ("scientificName" in content) {
      this.scientificName = new ScientificName(content["scientificName"]);
    }

    if ("defaultClassification" in content) {
      this.defaultClassification = new DefaultClassification(content["defaultClassification"]);
    }

    if ("vernacularNames" in content) {
      content["vernacularNames"].forEach((vernacularName: any) => {
        this.vernacularNames.push(new VernacularName(vernacularName));
      });
    }
  }
}
